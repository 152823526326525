import { useTranslation } from "react-i18next";

//* mui component imports
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { paymentOption } from "../../options/paymentOptions";
import PaymentOptionCardv2 from "../../component/cards/PaymentOptionCardv2";
import { useTheme } from "@mui/material";
import { useState } from "react";
// import AboutSponsor from "../../component/modal/AboutSponsor";
import { toast } from "react-toastify";
import { useStateValue } from "../../store";
// import useStripeForm from "../../hooks/payment/useStripeForm";
import { useNavigate } from "react-router";
import useChooseDossierPackage from "../../hooks/transaction/useChooseDossierPackage";
import usePayrexxGateway from "../../hooks/payment/usePayrexxGateway";
import useUserProfile from "../../hooks/profile/useUserProfile";
import { checkForEmpty } from "../../utils/checkDataFunction";

let clientUrl = process.env.REACT_APP_CLIENT_URL;

function DossierPayment() {
    const theme = useTheme();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [{ user_id }] = useStateValue();
    const {
        isLoading: userIsLoading,
        data: userData,
        status: userStatus,
    } = useUserProfile(user_id);

    let user = userStatus === "success" && userData.data.data[0];

    // const { mutate: onStripeMutate, isLoading: stripeIsLoading } =
    //     useStripeForm();

    const { mutate: onPayrexxMutate, isLoading: payrexxIsLoading } =
        usePayrexxGateway();

    // const [openAboutFreeSponsored, setOpenAboutFreeSponsored] = useState(false);

    // const onOpenAboutFreeSponsored = () => setOpenAboutFreeSponsored(true);

    // const onCloseAboutFreeSponsored = () => setOpenAboutFreeSponsored(false);

    const { mutate: onPayment, isLoading: isPaymentLoading } =
        useChooseDossierPackage();

    const onSelectPayment = (paymentMethod) => {
        if (paymentMethod === "invest") {
            // let payData = {
            //     userId: user_id,
            //     cancelUrl: "dossier-payment",
            // };

            // onStripeMutate(payData, {
            //     onSuccess: (data) => {
            //         let status = data?.data.statuscode;
            //         if (!status) {
            //             toast.error(data?.data?.message);
            //             return;
            //         }
            //         window.location.href = data?.data?.url;
            //     },
            //     onError: (errors) => {
            //         toast.error(`${t("we_are_facing_some_technical_issue")}`);
            //         console.error(errors);
            //     },
            // });

            let payData = {
                userId: user_id,
                fields: {
                    forename: { value: checkForEmpty(user.first_name, "-") },
                    surname: { value: checkForEmpty(user.last_name, "-") },
                    email: {
                        value: checkForEmpty(user.email, "-"),
                    },
                },
                successRedirectUrl: `${clientUrl}smart-dossier-payment-status?status=success`,
                failedRedirectUrl: `${clientUrl}smart-dossier-payment-status?status=failed`,
            };
            onPayrexxMutate(payData, {
                onSuccess: (data) => {
                    let status = data?.data.statuscode;
                    if (!status) {
                        toast.error(data?.data?.message);
                        return;
                    }

                    window.location.href = data?.data?.url;
                },
                onError: (errors) => {
                    toast.error(`${t("we_are_facing_some_technical_issue")}`);
                    console.error(errors);
                },
            });
            return;
        }

        handleCreateDossier();
        //navigate(`/payment-sponsor`);
    };

    const handleCreateDossier = () => {
        let packageDetails = {
            user_id: user_id,
            sponsor_id: null,
            type: "free",
            start_date: new Date(),
            expire_date: null,
            payment_for: "SMART_DOSSIER",
        };

        onPayment(packageDetails, {
            onSuccess: (data) => {
                if (!data?.data?.statuscode) {
                    toast.error(data?.data?.message);
                    return;
                }
                navigate(`/success-smart-dossier`, {
                    replace: true,
                });
            },
            onError: (errors) => {
                console.error(errors);
                toast.error(`${t("something_went_wrong")}`);
            },
        });
    };

    return (
        <>
            <Box
                sx={{
                    bgcolor: theme.palette.primary.main,
                    minHeight: "90vh",
                }}
            >
                <Container maxWidth="xl">
                    <Paper
                        sx={{
                            my: 10,
                            borderRadius: 7.5,
                        }}
                    >
                        <Container maxWidth="lg">
                            <Grid container rowSpacing={5}>
                                <Grid item xs={12}>
                                    <Stack>
                                        <Typography variant="h3">
                                            {t("select_your_smart_dossier")}
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {t(
                                                "select_your_smart_dossier_subtitle"
                                            )}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid
                                        item
                                        container
                                        rowSpacing={10}
                                        columnSpacing={5}
                                        px={{ xs: 1, lg: 3 }}
                                        justifyContent="center"
                                    >
                                        {paymentOption.map((option, index) => (
                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                lg={6}
                                                key={option.id}
                                            >
                                                <PaymentOptionCardv2
                                                    selected={
                                                        option.value ===
                                                        "invest"
                                                    }
                                                    shortText={option.shortText}
                                                    benefitList={
                                                        option.benefits
                                                    }
                                                    badgeLabel={option.label}
                                                    price={option.price}
                                                    onChoose={() =>
                                                        onSelectPayment(
                                                            option.value
                                                        )
                                                    }
                                                    loadingOnClick={
                                                        payrexxIsLoading
                                                    }
                                                    value={option.value}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                                p={2}
                            ></Stack>
                            {/*
                            <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                                p={2}
                            >
                                <Button
                                    variant="text"
                                    onClick={() =>
                                        navigate(-1, {
                                            replace: true,
                                        })
                                    }
                                >
                                    {t("back")}
                                </Button>
                                <Button
                                    color="secondary"
                                    onClick={onOpenAboutFreeSponsored}
                                >
                                    {t("how_does_free_sponsored_work")}
                                </Button>
                            </Stack>
                            */}
                        </Container>
                    </Paper>
                </Container>
            </Box>
            {/*
            <AboutSponsor
                open={openAboutFreeSponsored}
                onClose={onCloseAboutFreeSponsored}
            />
            */}
        </>
    );
}

export default DossierPayment;
