//* Mui component
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

//* router
import { Link } from "react-router-dom";

//* assets
import { NMLogoWithText } from "../../utils/imgImports";

import NavigationLinks from "./NavigationLinks";
import NavigationButtons from "./NavigationButtons";
import useResponsive from "../../hooks/responsive/useResponsive";

function DesktopNavigation() {
    const { isBelowCustom } = useResponsive(1290);
    return (
        <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
        >
            <Stack
                direction={"row"}
                alignItems={"center"}
                columnGap={isBelowCustom ? 4 : 7.5}
            >
                <Box>
                    <Link to={"/"}>
                        <img
                            src={NMLogoWithText}
                            style={{
                                width: "16rem",
                            }}
                            loading={"eager"}
                            alt="nextflat"
                        />
                    </Link>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        gap: isBelowCustom ? 2 : 4,
                    }}
                >
                    <NavigationLinks />
                </Box>
            </Stack>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: isBelowCustom ? 1.5 : 2.5,
                }}
            >
                <NavigationButtons />
            </Box>
        </Stack>
    );
}

export default DesktopNavigation;
