import React, { useEffect, useState } from "react";

import cookies from "js-cookie";

//*  using toast for success and error
import { toast, Zoom } from "react-toastify";

//* translation
import { useTranslation } from "react-i18next";

//* react-hook-form
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

//* libraries
import { useLocation, useNavigate } from "react-router-dom";

//* hooks and apis
import useAddProfileDetail from "../hooks/profile/useAddProfileDetails";
import useGetAllProfileDetailsById from "../hooks/profile/useGetAllProfileDetailsById";
import useGetDossierGPT from "../hooks/dossier/useDossierGPT";
import { useSalaryRange } from "../hooks/dossier/useSalaryRange";
import { useGetDossierScore } from "../hooks/dossier/useGetDossierScore";
import useResponsive from "../hooks/responsive/useResponsive";

//* store imports
import { useStateValue } from "../store/StateProvider";

//* loading components
import Loading from "../component/loading/Loading";

//* Schema
import { schema } from "../forms-schemas/dossier-schemas/DetailedFormSchema";
// *** by AAR
// Importing currentSection-oriented schemas
import step0Section0Schema from "../component/forms/smart_dossier_form/smart_dossier_stepper_form/form-schemas/tenant_one/step0Section0Schema";
import step0Section1Schema from "../component/forms/smart_dossier_form/smart_dossier_stepper_form/form-schemas/tenant_one/step0Section1Schema";
import step0Section2Schema from "../component/forms/smart_dossier_form/smart_dossier_stepper_form/form-schemas/tenant_one/step0Section2Schema";
import step0Section3Schema from "../component/forms/smart_dossier_form/smart_dossier_stepper_form/form-schemas/tenant_one/step0Section3Schema";
import step0Section4Schema from "../component/forms/smart_dossier_form/smart_dossier_stepper_form/form-schemas/tenant_one/step0Section4Schema";
import step0Section5Schema from "../component/forms/smart_dossier_form/smart_dossier_stepper_form/form-schemas/tenant_one/step0Section5Schema";

import t2step0Section0Schema from "../component/forms/smart_dossier_form/smart_dossier_stepper_form/form-schemas/tenant_two/step0Section0Schema";
import t2step0Section1Schema from "../component/forms/smart_dossier_form/smart_dossier_stepper_form/form-schemas/tenant_two/step0Section1Schema";
import t2step0Section2Schema from "../component/forms/smart_dossier_form/smart_dossier_stepper_form/form-schemas/tenant_two/step0Section2Schema";
import t2step0Section3Schema from "../component/forms/smart_dossier_form/smart_dossier_stepper_form/form-schemas/tenant_two/step0Section3Schema";
import t2step0Section4Schema from "../component/forms/smart_dossier_form/smart_dossier_stepper_form/form-schemas/tenant_two/step0Section4Schema";
import t2step0Section5Schema from "../component/forms/smart_dossier_form/smart_dossier_stepper_form/form-schemas/tenant_two/step0Section5Schema";

//* components imports
import DossierUploadDocument from "../component/forms/smart_dossier_form/common_dossier_form/DossierUploadDocument";
import SmartDossierSummaryView from "../component/forms/smart_dossier_form/common_dossier_form/SmartDossierSummaryView";
import { DossierMotivation } from "../component/forms/smart_dossier_form/smart_dossier_stepper_form/DossierMotivation";
import DossierTenantOne from "../component/forms/smart_dossier_form/smart_dossier_stepper_form/DossierTenantOne";
import DossierTenantTwo from "../component/forms/smart_dossier_form/smart_dossier_stepper_form/DossierTenantTwo";
import ArrowButton from "../component/elements/button/ArrowButton";
import DossierStepper from "../component/forms/smart_dossier_form/dossier_components/DossierStepper";
import PageNotFound from "../pages/Error-pages/PageNotFound";

//* mui components import
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";

//* mui icons imports
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import SummarizeIcon from "@mui/icons-material/Summarize";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";

import { getPrompt } from "../utils/prompt";

import { DEFAULT_VALUE } from "../component/forms/smart_dossier_form/dossierDefaultValues";

// *** by AAR
// Importing essential components and utilities for the smart dossier stepper form:
// - onSubmitData: Function to handle the submission of form data.
// - NavigationButtons: Component providing navigation buttons (e.g., Next, Previous) for form steps.
// - ConfirmationDialog: Component for displaying a dialog to confirm user actions, typically used before critical operations like data submission or step transitions.
// - handleNextStep: Utility function to handle the logic for proceeding to the next step in the form, including validation and state updates.
// - handlePrev: Utility function to handle the logic for going back to the previous step in the form.
// - handleGPTAnswer: Utility function to handle the chatGPT generating answer in description field.
// - doFormInitializationByEffect: Import the custom hook for form initialization.
import onSubmitData from "../component/forms/smart_dossier_form/smart_dossier_stepper_form/helper/OnSubmitData";
import NavigationButtons from "../component/forms/smart_dossier_form/smart_dossier_stepper_form/helper/NavigationButtons";
import ConfirmationDialog from "../component/forms/smart_dossier_form/smart_dossier_stepper_form/helper/ConfirmationDialog";
import {
    handleNext,
    handlePrev,
} from "../component/forms/smart_dossier_form/smart_dossier_stepper_form/utils/handleSteps";
import { handleGPTAnswer } from "../component/forms/smart_dossier_form/smart_dossier_stepper_form/utils/handleGPTAnswer";
import useFormInitializationByEffect from "../component/forms/smart_dossier_form/smart_dossier_stepper_form/utils/useFormInitializationByEffect";

import { motion, AnimatePresence } from "framer-motion";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Looks3OutlinedIcon from "@mui/icons-material/Looks3Outlined";
import Looks4OutlinedIcon from "@mui/icons-material/Looks4Outlined";
import Looks5OutlinedIcon from "@mui/icons-material/Looks5Outlined";
import Looks6OutlinedIcon from "@mui/icons-material/Looks6Outlined";
import LooksOneOutlinedIcon from "@mui/icons-material/LooksOneOutlined";
import LooksTwoOutlinedIcon from "@mui/icons-material/LooksTwoOutlined";
import Grid from "@mui/material/Grid";
import DossierSubStepper from "../component/forms/smart_dossier_form/dossier_components/DossierSubStepper";
import { DevTool } from "@hookform/devtools";

export default function MultiStepFormContext() {
    const [{ user_id }] = useStateValue();
    const { isBelowSm, isBelowMd } = useResponsive();
    const {
        isLoading: isDossierLoading,
        data: dossierData,
        status: dossierStatus,
        refetch,
    } = useGetAllProfileDetailsById(user_id);

    const navigate = useNavigate();

    const theme = useTheme();
    const { state } = useLocation();
    let editStep = state ? state.step : null;
    const [step, setStep] = useState(editStep || 0);

    const formSectionIcons = [
        { Icon: LooksOneOutlinedIcon },
        { Icon: LooksTwoOutlinedIcon },
        { Icon: Looks3OutlinedIcon },
        { Icon: Looks4OutlinedIcon },
        { Icon: Looks5OutlinedIcon },
        { Icon: Looks6OutlinedIcon },
    ];

    // *** by AAR
    // Initializing step navigation and dialog states:
    // - targetStepTmp: Retrieves the target step from the component's state, falling back to null if not present.
    // - targetStep: State variable to keep track of the target step in the stepper, initialized based on targetStepTmp or defaults to 0.
    // - openDialog: State variable controlling the visibility of the modal dialog, initialized to false (dialog not visible by default).
    let targetStepTmp = state ? state.step : null;
    const [targetStep, setTargetStep] = useState(targetStepTmp || 0);
    const [openDialog, setOpenDialog] = useState(false);

    // State variable for tracking the current section (zero to five in tenant 1 and 2) of the form within a particular step (dossierSteps).
    // It's used to handle section-based navigation and rendering within a multi-step form.
    let editCurrentSection = state ? state.currentSection : null;
    const [currentSection, setCurrentSection] = useState(
        editCurrentSection || 0
    );

    const { t } = useTranslation();
    const [reference, setReference] = useState({
        administration: "",
        employer: "",
    });
    const [tenantTwoReference, setTenantTwoReference] = useState({
        tenantTwoAdministration: "",
        tenantTwoEmployer: "",
    });

    const currentLanguageCode = cookies.get("i18next") || "en";
    const { data } = useSalaryRange();
    let salaryRangeData = data?.data?.result;

    const { mutate } = useAddProfileDetail();

    const {
        isLoading: isDossierScoreLoading,
        data: dossierScoreData,
        refetch: refetchScore,
    } = useGetDossierScore(user_id);
    let dossierScore = dossierScoreData?.data?.data;

    let currentValidationSchema;
    //console.log("Step" + step + "/Section" + currentSection)
    //const currentValidationSchema = step >= 4 ? schema[3] : schema[step];
    // TODO: generate name of file and component to import by building name string
    if (step === 0 && currentSection === 0) {
        currentValidationSchema = step0Section0Schema;
    } else if (step === 0 && currentSection === 1) {
        currentValidationSchema = step0Section1Schema;
    } else if (step === 0 && currentSection === 2) {
        currentValidationSchema = step0Section2Schema;
    } else if (step === 0 && currentSection === 3) {
        currentValidationSchema = step0Section3Schema;
    } else if (step === 0 && currentSection === 4) {
        currentValidationSchema = step0Section4Schema;
    } else if (step === 0 && currentSection === 5) {
        currentValidationSchema = step0Section5Schema;
    } else if (step === 1 && currentSection === 0) {
        currentValidationSchema = t2step0Section0Schema;
    } else if (step === 1 && currentSection === 1) {
        currentValidationSchema = t2step0Section1Schema;
    } else if (step === 1 && currentSection === 2) {
        currentValidationSchema = t2step0Section2Schema;
    } else if (step === 1 && currentSection === 3) {
        currentValidationSchema = t2step0Section3Schema;
    } else if (step === 1 && currentSection === 4) {
        currentValidationSchema = t2step0Section4Schema;
    } else if (step === 1 && currentSection === 5) {
        currentValidationSchema = t2step0Section5Schema;
    } else if (step >= 4) {
        currentValidationSchema = schema[3];
        //console.log("currentValidationSchema3")
    } else {
        currentValidationSchema = schema[step];
        //console.log("currentValidationSchema-step" + step)
    }
    const methods = useForm({
        defaultValues: DEFAULT_VALUE,
        resolver: yupResolver(currentValidationSchema),
        mode: "onChange",
    });

    const watchAnotherTenantMovingIn = methods.watch("isAnotherTenantMovingIn");

    // *** by AAR
    // Initializing state variables for tenant names and form state tracking:
    // - tenantOneFirstName: State variable for storing the first name of the first tenant, defaulting to 'tenant_one'.
    // - tenantTwoFirstName: State variable for storing the first name of the second tenant, defaulting to 'tenant_two'.
    // - formState: Object containing the current form state provided by react-hook-form's useForm.
    // - isDirty: Derived from formState, indicates if any form fields have been modified.
    const [tenantOneFirstName, setTenantOneFirstName] = useState("tenant_one");
    const [tenantTwoFirstName, setTenantTwoFirstName] = useState("tenant_two");
    const [tenantOneLastName, setTenantOneLastName] = useState("");
    const [tenantTwoLastName, setTenantTwoLastName] = useState("");

    const { formState } = methods;
    const { isDirty } = formState;

    // Initializing data and functions related to GPT (Generative Pre-trained Transformer):
    // - dosserGptLoading: Indicates whether the GPT data fetching is in progress.
    // - mutateDossierGPT: Function to trigger the GPT data fetching process.
    // - onGPTAnswer: Custom function handling the logic for processing and handling GPT answers.
    const dataForPrompt = {
        firstName: tenantOneFirstName,
        lastName: tenantOneLastName,
    };
    const prompt = getPrompt(dataForPrompt, currentLanguageCode);

    const {
        isLoading: dosserGptLoading,
        mutate: mutateDossierGPT,
        // status: dossierGPTSuccess,
    } = useGetDossierGPT();
    const onGPTAnswer = handleGPTAnswer(
        methods,
        mutateDossierGPT,
        currentLanguageCode,
        t,
        getPrompt
    );

    // Initializing form
    //
    // - useFormInitializationByEffect: Initialize form state and set tenant first names using the custom doFormInitializationByEffect hook.
    //    This hook abstracts the complex useEffect logic for setting initial form values based on the dossier data.
    //    It takes the dossier status, dossier data, form methods, and state setters for tenant names as arguments.
    useFormInitializationByEffect(
        dossierStatus,
        dossierData,
        methods,
        setTenantOneFirstName,
        setTenantTwoFirstName,
        setTenantOneLastName,
        setTenantTwoLastName,
        salaryRangeData
    );

    // const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    let dossierDetails = dossierData?.data?.data[0];
    let dossierAccountDetails = dossierDetails?.dossier_account_details;
    let dossierPartnerDetails = dossierDetails?.dossier_partner;
    let userCreateDetails = dossierDetails?.user_create_details[0];
    useEffect(() => {
        if (
            Array.isArray(dossierAccountDetails) &&
            dossierAccountDetails.length > 0
        ) {
            const referenceData = {
                administration:
                    dossierAccountDetails[0]?.administration_reference_status,
                employer: dossierAccountDetails[0]?.employer_reference_status,
            };
            setReference((prevState) => ({ ...prevState, ...referenceData }));
        }
    }, [dossierAccountDetails]);

    useEffect(() => {
        if (
            Array.isArray(dossierPartnerDetails) &&
            dossierPartnerDetails.length > 0
        ) {
            const referenceData = {
                tenantTwoAdministration:
                    dossierPartnerDetails[0]?.administration_reference_status,
                tenantTwoEmployer:
                    dossierPartnerDetails[0]?.employer_reference_status,
            };
            setTenantTwoReference((prevState) => ({
                ...prevState,
                ...referenceData,
            }));
        }
    }, [dossierPartnerDetails]);

    const dossierSteps = [
        tenantOneFirstName,
        tenantTwoFirstName,
        "documents",
        "motivation",
        "summary",
    ];

    const handleEditFormSubmit = (data) => {
        onSubmitData(data, {
            mutate,
            refetchScore,
            navigate,
            dossierSteps,
            step,
            user_id,
            t,
            salaryRangeData,
        });
    };

    // Initializing step navigation functions:
    // - handleNextStep: Custom function handling the logic for moving to the next step in the form.
    // - handlePrevStep: Custom function handling the logic for returning to the previous step in the form.
    const handleNextStep = handleNext(
        methods,
        step,
        watchAnotherTenantMovingIn,
        setStep,
        onGPTAnswer,
        handleEditFormSubmit
    );
    const handlePrevStep = handlePrev(
        watchAnotherTenantMovingIn,
        step,
        setStep,
        setCurrentSection
    );

    const handleReferenceStatus = (statusKey, statusValue) => {
        setReference((prevState) => {
            return { ...prevState, [statusKey]: statusValue };
        });
    };
    const handleTenantTwoReferenceStatus = (statusKey, statusValue) => {
        setTenantTwoReference((prevState) => {
            return { ...prevState, [statusKey]: statusValue };
        });
    };

    //  *** by AAR
    //     possible to build a NavigationContext to make the methods accessible in another way

    const handleCloseDialog = async (saveChanges) => {
        setOpenDialog(false);

        if (saveChanges) {
            let allValues = methods.getValues();

            try {
                await handleEditFormSubmit(allValues);
                methods.reset(methods.getValues());
            } catch (error) {
                console.error(error);
            }
        }
    };

    /*  *** by AAR
        handle click on icon of DossierStepper
     */
    const handleStepClick = async (index) => {
        const isStepValid = await methods.trigger();

        if (isStepValid) {
            setTargetStep(index);
            const tmpStep = step;

            setStep(index);
            setCurrentSection(0);

            if ((tmpStep === 0 || tmpStep === 1 || tmpStep === 3) && isDirty) {
                // *** by AAR
                // open dialog for save question via dossier stepper
                setOpenDialog(true);
            }
        }
    };
    const handleSubStepClick = (currentSection) => {
        setCurrentSection(currentSection);
    };

    /* *** by AAR
        handle click on save&proceed button below each section
    */
    const [direction, setDirection] = useState(1);

    const sectionVariants = {
        enter: { opacity: 0 },
        center: { opacity: 1 },
        exit: { opacity: 0 },
    };

    const goToNextSection = async () => {
        const maxIndex = 5;
        //console.log(currentSection);

        if (currentSection <= maxIndex) {
            window.scrollTo({ top: 0, behavior: "instant" });
            let allValues = methods.getValues();
            const isSectionValid = await methods.trigger();
            //console.log(allValues);

            //console.error("CurrentSection: " + currentSection);
            //console.error("Valid: " + isSectionValid);

            if (isSectionValid) {
                try {
                    //await saveSectionData(currentSection, allValues);
                    await handleEditFormSubmit(allValues);
                    if (currentSection === maxIndex) {
                        // console.log("section: " + currentSection);
                        // console.log("maxIndex: " + maxIndex);
                        setCurrentSection(0);
                        handleNextStep();
                    } else {
                        setCurrentSection(currentSection + 1);
                        setDirection(1);
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }
    };

    const goToPreviousSection = () => {
        if (currentSection > 0) {
            setCurrentSection(currentSection - 1);
            setDirection(-1);
        }
    };

    const commonProps = {
        t,
        theme,
        currentSection,
        step,
        handleStepClick,
    };

    const getForm = () => {
        switch (step) {
            case 0:
                return (
                    <DossierTenantOne
                        referenceStatus={reference}
                        handleReferenceStatus={handleReferenceStatus}
                        stepLabels={dossierSteps}
                        formSectionIcons={formSectionIcons}
                        commonProps={commonProps}
                        sectionVariants={sectionVariants}
                        direction={direction}
                    />
                );
            case 1:
                return (
                    <DossierTenantTwo
                        referenceStatus={tenantTwoReference}
                        handleReferenceStatus={handleTenantTwoReferenceStatus}
                        stepLabels={dossierSteps}
                        formSectionIcons={formSectionIcons}
                        commonProps={commonProps}
                    />
                );
            case 2:
                return (
                    <DossierUploadDocument
                        refetch={refetch}
                        stepLabels={dossierSteps}
                    />
                );
            case 3:
                return <DossierMotivation />;
            case 4:
                return <SmartDossierSummaryView stepLabels={dossierSteps} />;

            default:
                return <PageNotFound />;
        }
    };

    const icons = {
        1: <AccountCircleIcon />,
        2: <PersonAddAltIcon />,
        3: <SupervisedUserCircleIcon />,
        4: <AddAPhotoIcon />,
        5: <SummarizeIcon />,
    };

    const FormsButtons = () => {
        if (step < 2) {
            return null;
        } else if (step === dossierSteps.length - 1) {
            return (
                <Stack direction={"row"} justifyContent={"space-between"} p={2}>
                    <ArrowButton
                        variant="text"
                        arrowStart
                        onClick={handlePrevStep}
                    >
                        {t("back")}
                    </ArrowButton>
                    <ArrowButton
                        variant="contained"
                        onClick={methods.handleSubmit(handleEditFormSubmit)}
                    >
                        {t("submit")}
                    </ArrowButton>
                </Stack>
            );
        } else {
            return (
                <Stack direction={"row"} justifyContent={"space-between"} p={2}>
                    <ArrowButton
                        variant="text"
                        onClick={handlePrevStep}
                        arrowStart
                    >
                        {t("back")}
                    </ArrowButton>
                    <ArrowButton variant="contained" onClick={handleNextStep}>
                        {t("save_&_proceed")}
                    </ArrowButton>
                </Stack>
            );
        }
    };

    return (
        <Container maxWidth={isBelowSm ? "xl" : "lg"} sx={{ my: 4 }}>
            {/*
            {!isBelowSm && (
            */}
            <Box
                display="flex"
                justifyContent="center"
                sx={{
                    color: theme.palette.secondary.main,
                    width: "100%",
                    ".MuiStepper-root": {
                        width: "inherit",
                    },
                }}
            >
                <DossierStepper
                    sx={{
                        color: theme.palette.secondary.main,
                        width: "100%",
                        ".MuiStepper-root": {
                            width: "100%",
                        },
                    }}
                    icons={icons}
                    activeStep={step}
                    stepLabels={dossierSteps}
                    onStepClick={handleStepClick}
                    showSecondStep={
                        watchAnotherTenantMovingIn &&
                        watchAnotherTenantMovingIn.trim() !== "" &&
                        watchAnotherTenantMovingIn === "yes"
                    }
                />
            </Box>
            {/*
           )}
            */}

            {step <= 1 && (
                <Grid item xs={12}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        sx={{
                            color: theme.palette.secondary.main,
                            width: "100%",
                            ".MuiStepper-root": {
                                width: "inherit",
                            },
                        }}
                    >
                        <DossierSubStepper
                            sx={{
                                color: theme.palette.secondary.main,
                                width: "100%",
                                ".MuiStepper-root": {
                                    width: "100%",
                                },
                            }}
                            formSectionIcons={formSectionIcons}
                            currentSection={currentSection}
                            onSubStepClick={handleSubStepClick}
                        />
                    </Box>
                </Grid>
            )}

            {user_id !== null && isDossierLoading ? (
                <Loading />
            ) : (
                <FormProvider
                    {...methods}
                    score={dossierScore}
                    onSubmitData={handleEditFormSubmit}
                    handleGPTAnswer={onGPTAnswer}
                    dosserGptLoading={dosserGptLoading}
                >
                    {isBelowMd ? (
                        <Box px={1}>
                            <form>
                                {getForm()}
                                {/*<FormsButtons/>*/}
                            </form>
                        </Box>
                    ) : (
                        <Paper
                            elevation={5}
                            sx={{
                                borderRadius: 2,
                                px: {
                                    md: 3,
                                    xs: 0,
                                },
                            }}
                        >
                            <form>{getForm()}</form>
                        </Paper>
                    )}

                    {step > 1 && step < 5 ? (
                        <FormsButtons />
                    ) : (
                        (step === 0 || step === 1) && (
                            <NavigationButtons
                                currentSection={currentSection}
                                setCurrentSection={setCurrentSection}
                                goToPreviousSection={goToPreviousSection}
                                goToNextSection={goToNextSection}
                                activeStep={step}
                                handlePrevStep={handlePrevStep}
                                t={t}
                            />
                        )
                    )}
                </FormProvider>
            )}
            <DevTool control={methods.control} />
            <ConfirmationDialog
                open={openDialog}
                onClose={handleCloseDialog}
                step={step}
                t={t}
            />
        </Container>
    );
}
