import { useMutation } from "react-query";
import { Axios } from "../../axios/axios";

//post
function getDossierGPT(question) {
    return Axios.post("/chat_GPT/getAnswer", question);
}

export default function useGetDossierGPT() {
    return useMutation(getDossierGPT);
}
