import { checkForDossierViewEmptyDate } from "./checkDossierViewFunction";
export const getPrompt = (data, currentLanguage) => {
    //* Modifying prompt according to the user data
    // const getSmokingStatus = () => {
    //     if (data?.isSmoker === "yes") return "- I am a smoker\n";
    //     if (data?.isSmoker === "no") return "- I do not smoke\n";
    //     return "";
    // };
    // const getPetStatus = () => {
    //     if (data?.hasPet === "yes") return `-I have ${data?.pet}\n`;
    //     if (data?.hasPet === "no") return "- I do not have any pets\n";
    //     return "";
    // };
    // const getNoOfChildren = () => {
    //     if (data?.hasChildren === "yes")
    //         return `I have ${data?.noOfChildren} children`;
    //     return "";
    // };

    const getPersonalInfo = () => {
        return `- My name is ${data.firstName} ${data.lastName}.\n`;
    };

    const getEmployementStatus = () => {
        if (data?.employedOrNot === "yes") {
            return {
                role: "user",
                content: `In the 2nd paragraph, please mention my financial situation that about the company I am working, my salary, the current position and that I have good chances for promotion, I even get a reference from my boss.`,
            };
        }
        if (data?.employedOrNot === "no") {
            return {
                role: "user",
                content: `In the 2nd paragraph, I am applying for a rental property and explain my current unemployment status. Explanation should emphasize that I am actively looking for a job and have prepared financially to ensure their unemployment does not affect their ability to pay rent.`,
            };
        }
        return {
            role: "user",
            content: "",
        };
    };
    // const getSalaryAndPositionStatus = () => {
    //     if (data?.employedOrNot === "yes") {
    //         return `\n- my salary ${data?.monthlyIncome}, with my position in the corresponding company`;
    //     }
    //     return "";
    // };
    const getCompanyName = () => {
        if (data?.employedOrNot === "yes" && data?.companyNameAndAddress)
            return `\n5. company for which i am working :${data?.companyNameAndAddress}`;
        return "";
    };
    const getJob = () => {
        if (data?.employedOrNot === "yes" && data?.profession) {
            return ` \n6. job i do : ${data?.profession}`;
        }
        return "";
    };
    const getSalaryStatus = () => {
        if (data?.employedOrNot === "yes" && data?.profession) {
            return ` \n7. salary: ${data?.monthlyIncome}`;
        }
        return "";
    };
    return [
        {
            role: "system",
            content:
                " I want you to take the role of a professional consultant for apartment applications (tenant side), with a lot of marketing experience in relation to people who apply and the knowledge of property managers knows how to approach them psychologically, eg about facts, such as earnings.",
        },
        {
            role: "user",
            content: "Please start with 'Dear ladies and gentlemen'. Write a letter of motivation for applying for an apartment, from the point of view of a tenant, which is intended for property managers.",
        },
        /*
        // changed above, old is following
        {
            role: "user",
            content: `Please write a letter of motivation for applying for an apartment, from the point of view of a tenant, which is intended for property managers. Please Start directly from ${
                data?.currentAdministrationName !== ""
                    ? `Dear ${data?.currentAdministrationName}`
                    : "Dear ladies and gentlemen "
            } for example ${
                data?.currentAdministrationName !== ""
                    ? `Dear ${data?.currentAdministrationName}`
                    : "Dear ladies and gentlemen "
            } \n I hope.....`,
        },
        */
        {
            role: "user",
            content:
                " I would like you to help me write a short cover letter that consists of only three paragraphs and follows this standardized structure.",
        },
        // {
        //     role: "user",
        //     content:
        //         "The administration should be addressed by a letter head like in a letter to the government",
        // },
        {
            role: "user",
            content:
                "In the 1st paragraph, I mention my motivation, why I want to move and why I want to apply for the apartment and i want to present myself with ${data.firstName} ${data.lastName}.",
        },
        getEmployementStatus(),
        {
            role: "user",
            content:
                "In the 3rd paragraph bring in relevant data about myself, such as. \n- name\n- date of birth\n- about my family\n",
        },
        {
            role: "user",
            content: `Attributes of the tenant followed by name of placeholder.:\n1. first name :${
                data.firstName
            } \n2. surname: ${
                data.lastName
            }\n3. date of birth: ${checkForDossierViewEmptyDate(
                data.dateOfBirth
            )}\n${
                data?.hasChildren === "yes" &&
                `4. number of children, with age of children, separated in two placeholders : ${
                    data?.noOfChildren
                } and ${data?.childrensAverageAge?.map(
                    (element) => element.age
                )}`
            }${getCompanyName()}${getJob()}${getSalaryStatus()}`,
        },
        {
            role: "user",
            content:
                "Final instruction of work for you\n- make sure the cover letter has professional language and tone of voice\n- make sure the cover letter should not have head\n- make sure the cover letter is short and neat and you dont repeat information about the tenant\n- follows the best practices of the industry.",
        },
        {
            role: "user",
            content: `The letter should be written in a friendly emotional style, but short, with heart but not exaggerated. The placeholders in the text should be in the following choosen language and please write it in ${
                currentLanguage === "en" ? "English" : "German"
            }`,
        },
    ];
};
/*

        {
            role: "user",
            content: `Please start with "Dear ladies and gentlemen". Write a letter of motivation for applying for an apartment, from the point of view of a tenant, which is intended for property managers. Please Start directly from ${
                data?.currentAdministrationName !== ""
                    ? "Dear ladies and gentlemen "
                    : "Dear ladies and gentlemen "
            } for example ${
                data?.currentAdministrationName !== ""
                    ? "Dear ladies and gentlemen "
                    : "Dear ladies and gentlemen "
            } \n I hope.....`,
        },

{
            role: "user",
            content:
                "Final instruction of work for you\n- make sure the cover letter has professional language and tone of voice\n- make sure the cover letter should not have head\n- make sure the cover letter is short and neat and you dont repeat information about the tenant\n- follows the best practices of the industry\n- use placeholders for the tenant's data, which can be replaced later via template parsing\n- the format of the placeholders is: 1. each placeholder is enclosed by three underscores before and after the placeholder, 2. with the placeholder's name in uppercase.",
        },
 */