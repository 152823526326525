import React from "react";

//* translation
import {useTranslation} from "react-i18next";

//* react-hook-form
import {useFormContext} from "react-hook-form";

//* custom components
import {MainHeadingWithIcon} from "../dossier_components/DossierInfoBox";
import DossierFormSection from "../dossier_components/DossierFormSection";

//* mui components import
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

//* mui icons imports
import LooksOneOutlinedIcon from "@mui/icons-material/LooksOneOutlined";

//* custom progress bar
import {ScoreProgress} from "../../../score-progress/ScoreProgress";
import RichTextEditor from "../../../rich-text-editor/RichTextEditor";

export const DossierMotivation = () => {
    const {t} = useTranslation();
    const {control, score, reset, handleGPTAnswer, dosserGptLoading} =
        useFormContext();

    const handleResetGpt = () => {
        const myPrevData = localStorage.getItem("dossier-gpt");
        reset((value) => ({
            ...value,
            description: myPrevData,
        }));
    };

    return (
        <>
            <Grid container rowSpacing={2} my={2}>
                <Grid item xs={12} position='relative'>
                    <Stack
                        flexDirection={{xs: "column", md: "row"}}
                        justifyContent={"space-between"}
                        alignContent={"center"}
                    >
                        <Stack
                            sx={{width: "100%"}}
                            justifyContent={"center"}
                            alignContent={"center"}
                        >
                            <Typography variant='h3' textAlign={"center"}>
                                {t("edit_smart_dossier_motivation_letter")}
                            </Typography>
                        </Stack>
                        <ScoreProgress size={"75px"} score={score}/>
                    </Stack>
                </Grid>
                {/*
            <MainHeadingWithIcon
               textOne={t(
                  "write_few_words_about_you_and_show_roughly_who_you_are"
               )}
               textTwo={t("here_some_more_keys_hint")}
            />
            */}

                {/* user tells new administration */}
                <Grid item xs={12}>
                    <DossierFormSection
                        heading={
                            t("what_do_you_want_to_tell_the_new_administration") + ""
                        }
                        Icon={LooksOneOutlinedIcon}
                        // loading={dosserGptLoading}
                    >
                        <Typography sx={{m: 2}}>
                            {t("write_few_words_about_you_and_show_roughly_who_you_are")}
                        </Typography>

                        <Stack
                            direction='row'
                            columnGap={4}
                            justifyContent={"flex-end"}
                            my={1}
                        >
                            <Button
                                variant='text'
                                onClick={handleResetGpt}
                                disabled={!localStorage.getItem("dossier-gpt")}
                            >
                                {t("motivation_letter_reset_button")}
                            </Button>
                            <Button variant='text' onClick={handleGPTAnswer}>
                                {t("motivation_letter_generate_button")}
                            </Button>
                        </Stack>

                        <RichTextEditor
                            control={control}
                            name={"description"}
                            loading={dosserGptLoading}
                        />
                    </DossierFormSection>
                    <Typography sx={{m: 5}}>
                        {t("here_some_more_keys_hint")}
                    </Typography>
                </Grid>
            </Grid>
            <Backdrop
                sx={{
                    //   color: ,
                    backgroundColor: "rgb(0 0 0 / 24%)",
                    backdropFilter: " blur(5px)",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={dosserGptLoading}
            >
                <Stack alignItems={"center"} justifyContent={"center"}>
                    <CircularProgress color='secondary'/>
                    <Typography
                        sx={{color: (theme) => theme.palette.secondary.dark}}
                    >
                        <em>{t("motivation_letter_send_wait_spinner")}</em>
                    </Typography>
                </Stack>
            </Backdrop>
        </>
    );
};
