import React, { useCallback, useEffect, useRef, useState } from "react";

//* libraries
import Lightbox from "yet-another-react-lightbox";

//* dependency on yet-another-react-lightbox
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

//* mui components imports
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

//* mui icons imports
import LensBlurIcon from "@mui/icons-material/LensBlur";
import { propertyPlaceholder } from "../../../../assets/imgImport";
import { useTranslation } from "react-i18next";
import { checkForNull } from "../../../../utils/checkDataFunction";
import { defaultFlatImage } from "../../../../utils/imgImports";
import { Skeleton, useTheme } from "@mui/material";

function srcset(image, size, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${
            size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
}

let patternForSingleImage = [
    {
        rows: 2,
        cols: 4,
    },
];
let patternForTwoImage = [
    {
        rows: 2,
        cols: 2,
    },
    {
        rows: 2,
        cols: 2,
    },
];
let patternForThreeImage = [
    {
        rows: 2,
        cols: 2,
    },
    {
        rows: 1,
        cols: 2,
    },
    {
        rows: 1,
        cols: 2,
    },
];
let patternForFourImage = [
    {
        rows: 2,
        cols: 2,
    },
    {
        rows: 1,
        cols: 1,
    },
    {
        rows: 1,
        cols: 1,
    },
    {
        rows: 1,
        cols: 2,
    },
];
let patternForFiveImage = [
    {
        rows: 2,
        cols: 2,
    },
    {
        rows: 1,
        cols: 1,
    },
    {
        rows: 1,
        cols: 1,
    },
    {
        rows: 1,
        cols: 1,
    },
    {
        rows: 1,
        cols: 1,
    },
];

let getPattern = (length) => {
    switch (length) {
        case 1:
            return patternForSingleImage;
        case 2:
            return patternForTwoImage;
        case 3:
            return patternForThreeImage;
        case 4:
            return patternForFourImage;
        case 5:
            return patternForFiveImage;

        default:
            return patternForFiveImage;
    }
};

function PropertyImages({ propertyDetails, imageUrl, height, width }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const [index, setIndex] = useState(-1);
    const [validImages, setValidImages] = useState([]);
    const [isImagesLoading, setIsImagesLoading] = useState(true);
    let propertyImages = propertyDetails?.images;
    const generatePropertyImageArr = useCallback(() => {
        if (checkForNull(propertyImages) === null) return null;
        let propertyParse = JSON.parse(propertyImages);
        let propertyImageArray = Object.values(propertyParse);
        return propertyImageArray
            .filter((image) => checkForNull(image) !== null)
            .map((filteredImg) => imageUrl + filteredImg);
    }, [imageUrl, propertyImages]);

    useEffect(() => {
        setIsImagesLoading(true);
        const validateImageUrl = (url) => {
            return new Promise((resolve) => {
                const img = new Image();
                img.onload = () => resolve(true);
                img.onerror = () => resolve(false);
                img.src = url;
            });
        };

        const validateImages = async () => {
            const validImagesPromises = generatePropertyImageArr().map(
                async (img) => {
                    const isValid = await validateImageUrl(img);
                    return isValid ? img : null;
                }
            );

            const validImagesResults = await Promise.all(validImagesPromises);

            setValidImages(validImagesResults.filter((img) => img !== null));
        };

        if (
            Array.isArray(generatePropertyImageArr()) &&
            generatePropertyImageArr().length > 0
        ) {
            validateImages();
        }
        setIsImagesLoading(false);
    }, [generatePropertyImageArr, imageUrl, propertyImages]);

    // for hiding the navigation buttons
    // https://github.com/igordanchenko/yet-another-react-lightbox/discussions/44

    if (isImagesLoading) {
        return (
            <Box>
                <div className="gallery">
                    <Skeleton variant="rectangular" height={"inherit"} />
                    <Skeleton variant="rectangular" height={"inherit"} />
                    <Skeleton variant="rectangular" height={"inherit"} />
                    <Skeleton variant="rectangular" height={"inherit"} />
                    <Skeleton variant="rectangular" height={"inherit"} />
                </div>
            </Box>
        );
    }

    if (!isImagesLoading && validImages.length === 0) {
        return (
            <Box
                width={"100%"}
                height={"100%"}
                minHeight={400}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                bgcolor={theme.palette.grey[200]}
                border={`1px solid ${theme.palette.primary.main}`}
                borderRadius={2}
            >
                <img
                    src={defaultFlatImage}
                    alt={propertyDetails?.name}
                    height={180}
                    style={{
                        objectFit: "cover",
                    }}
                />
            </Box>
        );
    }

    const PropertyDetailsImages = () => {
        return (
            <ImageList variant="quilted" cols={4} rowHeight={200}>
                {validImages?.map((img, index) => {
                    const pattern = getPattern(validImages?.length)[index];
                    if (!pattern) {
                        //console.error('Muster für Index', index, 'nicht gefunden.');
                        return null;
                    }

                    return (
                        <ImageListItem
                            key={index}
                            onClick={() => setIndex(index)}
                            cols={getPattern(validImages?.length)[index].cols}
                            rows={getPattern(validImages?.length)[index].rows}
                            sx={{
                                borderRadius: "18px",
                                overflow: "hidden",
                                border: "1px solid",
                                borderColor: "primary.main",
                                img: {
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                },
                            }}
                        >
                            <img
                                {...srcset(
                                    img,
                                    200,
                                    getPattern(validImages?.length)[index].rows,
                                    getPattern(validImages?.length)[index].cols
                                )}
                                alt={"property"}
                            />
                        </ImageListItem>
                    );
                })}
            </ImageList>
        );
    };

    return (
        <>
            <Box sx={{ position: "relative" }}>
                <Box component={"div"} sx={{ cursor: "pointer" }}>
                    <PropertyDetailsImages />
                </Box>
                {/* <PropertyDetailsImages /> */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setIndex(0)}
                    sx={{
                        position: "absolute",
                        right: 40,
                        bottom: 18,

                        borderRadius: 2,
                    }}
                    startIcon={<LensBlurIcon />}
                >
                    {t("show_all_images")}
                </Button>
            </Box>
            <Lightbox
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                slides={validImages?.map((images) => ({
                    src: images,
                }))}
                plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
            />
        </>
    );
}

export default PropertyImages;
