import React, { useCallback, useEffect, useRef, useState } from "react";

//* custom imports
import { Img } from "../img/Img";

//* mui components import
import { Box, ButtonBase, Skeleton, useTheme } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

//* image imports
import { propertyPlaceholder } from "../../assets/imgImport";
import { Fragment } from "react";

//* mui icon components
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";

import "yet-another-react-lightbox/styles.css";
import Lightbox from "yet-another-react-lightbox";

import Inline from "yet-another-react-lightbox/plugins/inline";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import { niceHouse } from "../../assets/imgImport";
import { checkForNull } from "../../utils/checkDataFunction";
import DefaultPropertySvg from "../svg/DefaultPropertySvg";
import { defaultFlatImage } from "../../utils/imgImports";

const CarouselButton = ({ children }) => {
    return (
        <ButtonBase
            sx={{
                height: 25,
                width: 25,
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "none",
                outline: "none",
                bgcolor: "#ffffff",
                opacity: 0.9,

                transition: "box-shadow,opacity 300ms ease-in-out",
                "&:hover": {
                    bgcolor: "#fff",
                    opacity: 1,
                    boxShadow: "0px 0px 6px 2px #edebebb5",
                },
            }}
        >
            {children}
        </ButtonBase>
    );
};

export const PropertyNameTooltip = ({ children, property }) => {
    return (
        <Tooltip
            title={property}
            followCursor
            arrow
            componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: "transparent",
                        "& .MuiTooltip-arrow": {
                            color: "primary",
                        },
                    },
                },
            }}
        >
            {children}
        </Tooltip>
    );
};

export const PropertyCategoryAndPrice = ({
    children,
    color,
    background,
    styleProps,
}) => {
    return (
        <Typography
            component="p"
            sx={{
                fontSize: "0.75rem",
                fontWeight: 500,
                color: color,
                background: background,
                width: "fit-content",
                marginTop: "7px",
                borderRadius: "12px",
                ...styleProps,
            }}
        >
            {children}
        </Typography>
    );
};

export const CarousalImages = ({
    propertyImages,
    altTags,
    imageUrl,
    height,
    width,
    imageStyleProps,
}) => {
    const theme = useTheme();
    const mainPrimaryColor = theme.palette.primary.main;
    const [index, setIndex] = useState(0);
    const [validImages, setValidImages] = useState([]);
    const [isImagesLoading, setIsImagesLoading] = useState(true);
    const generatePropertyImageArr = useCallback(() => {
        if (checkForNull(propertyImages) === null) return null;

        let propertyParse = JSON.parse(propertyImages);
        let propertyImageArray = Object.values(propertyParse);
        return propertyImageArray
            .filter((image) => checkForNull(image) !== null)
            .map((filteredImg) => imageUrl + filteredImg);
    }, [imageUrl, propertyImages]);

    useEffect(() => {
        setIsImagesLoading(true);
        const validateImageUrl = (url) => {
            return new Promise((resolve) => {
                const img = new Image();
                img.onload = () => resolve(true);
                img.onerror = () => resolve(false);
                img.src = url;
            });
        };

        const validateImages = async () => {
            const validImagesPromises = generatePropertyImageArr().map(
                async (img) => {
                    const isValid = await validateImageUrl(img);
                    return isValid ? img : null;
                }
            );

            const validImagesResults = await Promise.all(validImagesPromises);

            setValidImages(validImagesResults.filter((img) => img !== null));
        };

        if (
            Array.isArray(generatePropertyImageArr()) &&
            generatePropertyImageArr().length > 0
        ) {
            validateImages();
        }
        setIsImagesLoading(false);
    }, [generatePropertyImageArr, imageUrl, propertyImages]);

    // for hiding the navigation buttons
    // https://github.com/igordanchenko/yet-another-react-lightbox/discussions/44

    if (isImagesLoading) {
        return (
            <Skeleton
                variant="rectangular"
                width="100%"
                height="250px"
                sx={{
                    borderRadius: "8px",
                    outline: (theme) =>
                        `${theme.palette.primary.main} solid 1px`,
                }}
            />
        );
    }

    if (
        !isImagesLoading &&
        Array.isArray(validImages) &&
        validImages.length === 0
    ) {
        return (
            <Box
                width={"100%"}
                height={"100%"}
                minHeight={250}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                bgcolor={theme.palette.grey[200]}
                border={`1px solid ${mainPrimaryColor}`}
                borderRadius={2}
            >
                <img
                    src={defaultFlatImage}
                    alt={altTags}
                    height={180}
                    style={{
                        objectFit: "cover",
                    }}
                />
            </Box>
        );
    }
    return (
        <>
            <Lightbox
                index={index}
                slides={validImages?.map((imgSrc) => ({
                    src: imgSrc,
                    height: height,
                    width: width,
                    alt: altTags,
                }))}
                plugins={[Inline]}
                // slideshow={{ ref: slideshowRef }}
                render={{
                    buttonSlideshow: () => null,
                    iconPrev: () => (
                        <ChevronLeftOutlinedIcon
                            sx={{
                                color: theme.palette.primary.dark,
                                fontSize: 18,
                            }}
                        />
                    ),
                    iconNext: () => (
                        <ChevronRightOutlinedIcon
                            sx={{
                                color: theme.palette.primary.dark,
                                fontSize: 18,
                            }}
                        />
                    ),
                }}
                on={{
                    view: ({ index }) => setIndex(index),
                }}
                carousel={{
                    finite: false,
                    padding: 0,
                    spacing: 0,
                    imageFit: "cover",
                    preload: 2,
                    imageProps: { loading: "lazy" },
                    borderRadius: "12px",
                    border: "1px solid",
                    borderColor: mainPrimaryColor,
                }}
                styles={{
                    container: {
                        borderRadius: "8px",
                        border: "1px solid",
                        borderColor: mainPrimaryColor,
                        backgroundColor: "#fff",
                    },
                }}
                inline={{
                    style: {
                        width: "100%",
                        maxWidth: "900px",
                        height: height,
                        aspectRatio: "1",
                        margin: "0 auto",
                    },
                }}
            />
        </>
    );
};
