import React from "react";

//*  Translation
import { useTranslation } from "react-i18next";

//* libraries
import { useNavigate } from "react-router-dom";

//* SVG
import DossierCreationSvg from "../../component/svg/DossierCreationSvg";
//* mui components imports
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useStateValue } from "../../store";
import { useCheckDossier } from "../../hooks/profile/useCheckDossier";

function DossierCreationAlert() {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const [{ user_id }] = useStateValue();

    const {
        isLoading: checkDossierIsLoading,
        data: checkDossierCreated,
        status: checkDossierStatus,
    } = useCheckDossier(user_id);

    const isDossierCreated = checkDossierCreated?.data?.briefFormFilled;

    const handleRedirect = () => {
        if (checkDossierStatus === "success" && isDossierCreated) {
            navigate("/account/fs/smart-dossier");
            return;
        }
        if (checkDossierStatus === "success" && !isDossierCreated) {
            navigate("/brief-dossier-form");
            return;
        }
    };

    return (
        <Container maxWidth="lg" sx={{ p: 5 }}>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    md={6}
                    display={"flex"}
                    justifyContent={"center"}
                >
                    <Stack spacing={3} justifyContent={"center"}>
                        <Typography variant="h3">{t("thank_you")}</Typography>
                        <Typography variant="p">
                            {t("smart_dossier_is_on_the_way")}
                        </Typography>
                        <Stack direction="row">
                            <Button
                                variant="contained"
                                size="medium"
                                disabled={checkDossierIsLoading}
                                onClick={handleRedirect}
                            >
                                {t("complete_your_dossier_now")}
                            </Button>
                        </Stack>
                    </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                    <DossierCreationSvg />
                </Grid>
            </Grid>
        </Container>
    );
}

export default DossierCreationAlert;
