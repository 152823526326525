export const paymentOption = [
    // {
    //    id: 1,
    //    value: "free",
    //    label: "free_benefit_label",
    //    benefits: [
    //       "free_benefit_one",
    //       "free_benefit_two",
    //       "free_benefit_three",
    //       "free_benefit_four",
    //       "free_benefit_five",
    //    ],
    //    shortText: "free_benefit_short_text",
    //    slug: "free",
    // },
    {
        id: 1,
        value: "invest",
        label: "invest_benefit_label",
        subLabel: "invest_benefit_sub_label",
        benefits: [
            "invest_benefit_one",
            "invest_benefit_two",
            "invest_benefit_three",
            "invest_benefit_four",
            "invest_benefit_five",
        ],
        shortText: "invest_benefit_short_text",
        price: 49,
        slug: "payment",
    },
    {
        id: 2,
        value: "free",
        label: "free_benefit_label",
        benefits: [
            "free_benefit_one",
            "free_benefit_two",
            "free_benefit_three",
            "free_benefit_four",
            "free_benefit_five",
        ],
        shortText: "free_benefit_short_text",
        price: 0,
        slug: "free",
    },
];

/*
    {
        id: 2,
        value: "sponsor",
        label: "sponsor_benefit_label",
        benefits: [
            "sponsor_benefit_one",
            "sponsor_benefit_two",
            "sponsor_benefit_three",
            "sponsor_benefit_four",
            "sponsor_benefit_five",
        ],
        shortText: "sponsor_benefit_short_text",
        price: 0,
        slug: "sponsor",
    },
 */