import React, { Fragment, useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container,
    Typography,
    Button,
    Grid,
    Stack,
    Chip,
    Skeleton,
    Box,
} from "@mui/material";
import styled from "@emotion/styled";
import AddIcon from "@mui/icons-material/Add";
import MinimizeIcon from "@mui/icons-material/Minimize";
import ApplyToSmartDossier from "../../component/section/ApplyToSmartDossier";
import { useTranslation } from "react-i18next";
import { useGetFaqCategory, useGetFaqs } from "../../hooks/faq/useFaq";
import cookies from "js-cookie";
import Lottie from "lottie-react";
import { NoData } from "../../utils/imgImports";

const ContainerBox = styled(Container)(() => ({
    "&.MuiContainer-root": {
        padding: 0,
    },
}));

const CategoryChip = styled(Chip)(({ theme }) => ({
    "& .MuiChip-label": {
        ...theme.typography.subtitle2,
    },
}));

const dummyArr = Array.from({ length: 5 }, (_, i) => {
    return { id: i + 1, label: `${i + 1}`, value: `${i + 1}` };
});

const NoFaq = () => {
    return (
        <Stack
            height={800}
            width={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
        >
            <Lottie animationData={NoData} />
        </Stack>
    );
};

const Faq = () => {
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get("i18next") || "en";
    const { isLoading: faqCategoryIsLoading, data: faqCategoryData } =
        useGetFaqCategory(currentLanguageCode);
    const [category, setCategory] = useState("");
    const {
        isLoading,
        data: faqData,
        hasNextPage,
        fetchNextPage,
    } = useGetFaqs({
        lang: currentLanguageCode,
        category_id: category,
    });

    const [expanded, setExpanded] = useState(null);
    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : null);
    };

    const handleCategory = (id) => setCategory(id);
    const getChipVariant = (id) => (id === category ? "filled" : "outlined");
    let faqCategory = faqCategoryData?.data?.result;

    return (
        <ContainerBox maxWidth="xl">
            <Container maxWidth="lg">
                <Stack sx={{ mb: 8, pt: 10, gap: "15px" }} width={{ md: 750 }}>
                    <Typography variant="h3">
                        {t("question_about_smart_dossier")}
                    </Typography>
                    <Typography variant="subtitle1">
                        {t("our_smart_dossier_revolutionizes_the_way")}
                    </Typography>
                </Stack>
                <Grid container rowSpacing={4}>
                    <Grid item container spacing={2}>
                        {faqCategoryIsLoading ? (
                            dummyArr?.map((el) => (
                                <Grid item key={el.id}>
                                    <Skeleton
                                        variant="rounded"
                                        width={100}
                                        height={25}
                                    />
                                </Grid>
                            ))
                        ) : (
                            <>
                                <Grid item>
                                    <CategoryChip
                                        label={t("all")}
                                        color="secondary"
                                        variant={getChipVariant("")}
                                        onClick={() => handleCategory("")}
                                    />
                                </Grid>
                                {faqCategory?.map((category) => (
                                    <Grid item key={category.id}>
                                        <CategoryChip
                                            label={category.name}
                                            variant={getChipVariant(
                                                category.id
                                            )}
                                            color="secondary"
                                            onClick={() =>
                                                handleCategory(category.id)
                                            }
                                        />
                                    </Grid>
                                ))}
                            </>
                        )}
                    </Grid>
                    <Grid item container rowSpacing={2}>
                        {isLoading
                            ? dummyArr?.map((el) => (
                                  <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      key={el.id}
                                  >
                                      <Skeleton
                                          variant="rounded"
                                          width={"100%"}
                                          height={145}
                                      />
                                  </Grid>
                              ))
                            : faqData?.pages?.map((group, index) => {
                                  if (!group?.data?.results) return <NoFaq />;
                                  return (
                                      <Fragment key={index}>
                                          {group?.data?.results?.map((faq) => (
                                              <Grid
                                                  item
                                                  xs={12}
                                                  md={12}
                                                  lg={12}
                                                  key={faq.id}
                                              >
                                                  <Accordion
                                                      key={faq.id}
                                                      expanded={
                                                          expanded ===
                                                          `panel${faq.id}`
                                                      }
                                                      onChange={handleAccordionChange(
                                                          `panel${faq.id}`
                                                      )}
                                                      sx={{ mb: 2 }}
                                                  >
                                                      <AccordionSummary
                                                          expandIcon={
                                                              expanded ===
                                                              `panel${faq.id}` ? (
                                                                  <MinimizeIcon />
                                                              ) : (
                                                                  <AddIcon />
                                                              )
                                                          }
                                                      >
                                                          <Typography
                                                              variant={"h5"}
                                                          >
                                                              {faq.questions}
                                                          </Typography>
                                                      </AccordionSummary>
                                                      <AccordionDetails>
                                                          <Typography variant="subtitle2">
                                                              {faq.answer}
                                                          </Typography>
                                                      </AccordionDetails>
                                                  </Accordion>
                                              </Grid>
                                          ))}
                                      </Fragment>
                                  );
                              })}
                    </Grid>
                    <Grid item xs={12}>
                        {hasNextPage && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={fetchNextPage}
                                sx={{
                                    float: "right",
                                }}
                            >
                                {t("show_more")}
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Container>
            <Box my={3}>
                <ApplyToSmartDossier coloredBg />
            </Box>
        </ContainerBox>
    );
};

export default Faq;
