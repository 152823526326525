import { toast, Zoom } from "react-toastify";

export const handleGPTAnswer = (methods, mutateDossierGPT, currentLanguageCode, t, getPrompt) => async () => {
    let gptQuestion = {
        question: getPrompt(methods.getValues(), currentLanguageCode),
    };

    mutateDossierGPT(gptQuestion, {
        onSuccess: (data) => {
            if (!data?.data?.status) {
                toast.error(
                    "Oops! something went wrong please try again later!",
                    { transition: Zoom }
                );
                return;
            }
            let content = data?.data?.message?.content
                ?.replace(/^((\r|\n)|(\r\n))+/g, "")
                ?.replace(/(?:\r\n|\r|\n)/g, "<br />");
            localStorage.setItem("dossier-gpt", content);
            methods.reset((value) => ({
                ...value,
                description: content,
            }));
        },
        onError: (errors) => {
            toast.error(`${t("we_are_facing_some_technical_issue")}`);
            console.error(errors);
        },
    });
};
