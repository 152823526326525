//* Mui component
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

//* router
import { Link, useLocation } from "react-router-dom";

//* assets
import { NMLogoWithText } from "../../utils/imgImports";

//* component
import useResponsive from "../../hooks/responsive/useResponsive";
import NavigationButtons from "./NavigationButtons";
import NavigationLinks from "./NavigationLinks";

import LanguageSelector from "../language-selector/LanguageSelector";
import HIDE_NAVIGATION_LINKS from "../../options/hideNavigationLinks";

const imageStyle = {
    height: "2.25rem",
};

function MobileNavigation({ handleDrawerOpen }) {
    const { pathname } = useLocation();
    const { isAboveLg, isAboveMd } = useResponsive();

    if (HIDE_NAVIGATION_LINKS.includes(pathname)) {
        return (
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
            >
                <Box>
                    <Link to={"/"}>
                        <img
                            src={NMLogoWithText}
                            style={imageStyle}
                            loading={"eager"}
                            alt="nextflat"
                        />
                    </Link>
                </Box>
                <LanguageSelector />
            </Stack>
        );
    }

    return (
        <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
        >
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
            >
                <Box>
                    <Link to={"/"}>
                        <img
                            src={NMLogoWithText}
                            style={imageStyle}
                            style={{
                                width: "15rem",
                            }}
                            loading={"eager"}
                            alt="nextflat"
                        />
                    </Link>
                </Box>
                {isAboveLg && (
                    <Stack direction={"row"} columnGap={4}>
                        <NavigationLinks />
                    </Stack>
                )}
            </Stack>

            <Stack direction={"row"} columnGap={2.5} alignItems={"center"}>
                {isAboveMd && <NavigationButtons />}
                <IconButton size="large" onClick={handleDrawerOpen}>
                    <Icon color="primary">menu</Icon>
                </IconButton>
            </Stack>
        </Stack>
    );
}

MobileNavigation.propTypes = {
    handleDrawerClose: PropTypes.func,
    handleDrawerOpen: PropTypes.func,
};

export default MobileNavigation;
